import { Box, IconButton, Modal, Typography } from '@mui/material';
import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import { FlexBox } from '../../components/tableItems';
import { MainButton } from '../../components/buttons';

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 380,
  bgcolor: 'background.paper',
  borderRadius: '7px',
  boxShadow: 24,
  px: 3,
  pt: 2,
  pb: 3,
};

interface AddWarningLinkProps {
  open: boolean;
  closeModal: (value: boolean) => void;
  continueModal: () => void;
}

const AddWarningLink: React.FC<AddWarningLinkProps> = ({
  open,
  closeModal,
  continueModal,
}) => {
  return (
    <Modal open={open}>
      <Box sx={style}>
        <>
          <Box justifyContent="end" display="flex">
            <IconButton onClick={() => closeModal(false)} sx={{ fontSize: 17 }}>
              <CloseIcon fontSize="inherit" />
            </IconButton>
          </Box>
          <Typography
            fontWeight={700}
            fontSize={17}
            textAlign="center"
            textTransform="capitalize"
          >
            Warning Add Link
          </Typography>
          <Typography marginTop={2} fontSize={14} textAlign="center">
            If you continue to change tabs, your progress while creating a link
            will be deleted
          </Typography>
          <Box marginTop={3.5}>
            <FlexBox marginTop={3} justifyContent="center">
              <MainButton
                onClick={() => closeModal(false)}
                variant="contained"
                sx={{
                  backgroundColor: '#F0F0F0',
                  color: 'black',
                  marginRight: 1,
                  '&:hover': {
                    backgroundColor: '#F2F2F2',
                  },
                }}
              >
                Cancel
              </MainButton>
              <MainButton
                variant="contained"
                onClick={continueModal}
                disabled={false}
                loading={false}
                sx={{ marginLeft: 1 }}
              >
                Continue
              </MainButton>
            </FlexBox>
          </Box>
        </>
      </Box>
    </Modal>
  );
};

export default AddWarningLink;
