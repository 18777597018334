import { create } from 'zustand';
import { LinkFormDefault } from '../../models/LinkForm';
import { ContactsFormLink, ObjectLinks } from '../../models/Forms';

interface LinkFormStore {
  linkForm: ObjectLinks;
  updateLinkForm: (obj: Partial<ObjectLinks>) => void;
  replaceLinkForm: (obj: ObjectLinks) => void;
  editLinkForm?: ObjectLinks;
  addContact: () => void;
  deleteContact: (index: number) => void;
  updateContact: (index: number, contact: Partial<ContactsFormLink>) => void;
  replaceContacts: (contacts: ContactsFormLink[]) => void;
}

const useLinkForm = create<LinkFormStore>(set => ({
  linkForm: LinkFormDefault,
  updateLinkForm: obj =>
    set(state => ({
      linkForm: { ...state.linkForm, ...obj },
    })),
  replaceLinkForm: obj => set({ linkForm: obj }),
  addContact: () =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts: [
          ...state.linkForm.contacts,
          {
            name: '',
            email: '',
            is_form: false,
            status: 'open',
            is_default: true,
            validation_status: 'active',
          },
        ],
      },
    })),
  deleteContact: index =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts: state.linkForm.contacts.filter((_, i) => i !== index),
      },
    })),

  updateContact: (index, contact) =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts: state.linkForm.contacts.map((c, i) =>
          i === index ? { ...c, ...contact } : c,
        ),
      },
    })),
  replaceContacts: contacts =>
    set(state => ({
      linkForm: {
        ...state.linkForm,
        contacts,
      },
    })),
}));

export default useLinkForm;
