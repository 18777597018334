import React, { useContext, useEffect, useState } from 'react';
import relativetime from 'dayjs/plugin/relativeTime';
import ArticleIcon from '@mui/icons-material/Article';
import dayjs from 'dayjs';
import {
  Box,
  IconButton,
  Link,
  Skeleton,
  TableCell,
  TableRow,
  Tooltip,
  Typography,
} from '@mui/material';
import TableWrapper from '../../../components/Table/TableWrapper';
import TableHeadComp from '../../../components/Table/TableHead.tsx/TableHeadComp';
import { masterLinkHeadTable } from '../../../models/Table';
import { Order } from '../../../models/General';
import {
  FlexBox,
  FlexBoxBetween,
  TableCellCustom,
} from '../../../components/tableItems';

import GeneralContext from '../../../context/GeneralActions';
import { GlobalContextType } from '../../../models/Context';
import { ClaimsInt } from '../../../models/Links';
import MainDrawer from '../../../components/Drawers/MainDrawer';
import EditClaim from '../../../components/Forms/Links/EditClaim';
import { claimCounts } from '../../../helpers/claims';
import { StyledTableRow } from '../../../components/LinksTable/LinksTable';

interface ClaimsProps {
  loading: boolean;
  error: boolean;
  claimsArr: ClaimsInt[];
  fetchInfo: () => Promise<void>;
  handleMessage: (message: string, severity: 'success' | 'error') => void;
  count: number;
  tab: number;
  searchProps?: {
    onKeyDown: () => void;
    value?: string;
    onChangeNormal: (query: string) => void;
    disabled: boolean;
  };
}

const visibility = {
  link: true,
  site: true,
  owner: true,
  approved_by: true,
  // status: true,
  total_points: true,
  found: true,
  date_approved: true,
  created: true,
  link__project: true,
  // link__found: true,
};

const MasterLinks = ({
  loading,
  error,
  claimsArr,
  fetchInfo,
  count,
  handleMessage,
  tab,
  searchProps,
}: ClaimsProps) => {
  dayjs.extend(relativetime);
  const [isLoadingCount, setIsLoadingCount] = useState(false);
  const [fullCount, setFullCount] = useState({ total: 0, count: 0 });
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [openDrawer, setOpenDrawer] = useState(false);
  const [order, setOrder] = useState<Order>('asc');
  const [orderBy, setOrderBy] = useState<string>('url');
  const [claimEdit, setClaimEdit] = useState(0);

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setGlobalContext({
      ...GlobalContext,
      master: {
        ...GlobalContext.master,
        order: `${orderRequest === 'desc' ? '-' : ''}${property}`,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      master: { ...GlobalContext.master, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      master: {
        ...GlobalContext.master,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };

  const handleEdit = (claim: number) => {
    setClaimEdit(claim);
    setOpenDrawer(true);
  };

  const getCount = async () => {
    setIsLoadingCount(true);
    try {
      const resp = await claimCounts(
        GlobalContext.master.query_filters.replace('&', '?'),
      );
      setFullCount({ count: resp.results_count, total: resp.total_points_sum });
    } catch (err) {
      setFullCount({ count: 0, total: 0 });
    } finally {
      setIsLoadingCount(false);
    }
  };

  useEffect(() => {
    if (tab === 4) getCount();
  }, [GlobalContext.master.query_filters]);

  return (
    <>
      {isLoadingCount && (
        <Box sx={{ width: 100 }}>
          <Skeleton />
          <Skeleton animation="wave" />
        </Box>
      )}
      {!isLoadingCount && (
        <Box>
          <Typography fontSize={15} fontWeight={600}>
            Total Points: {fullCount.total.toFixed(2)}
          </Typography>
          <Typography fontSize={13} fontWeight={400} marginTop={0.5}>
            Results Count: {fullCount.count}
          </Typography>
        </Box>
      )}
      <TableWrapper
        search
        searchProps={searchProps}
        loading={loading}
        error={error}
        type="Claims"
        pagination
        totalCells={8}
        fetchInfo={fetchInfo}
        itemsLength={claimsArr.length}
        countItems={count}
        pageSize={GlobalContext.master.page_size}
        page={GlobalContext.master.page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        HeadOfTable={
          <TableHeadComp
            columnsVisibility={visibility}
            headCells={masterLinkHeadTable}
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
          />
        }
      >
        {claimsArr.map((row, index) => {
          const labelId = `claims-table-checkbox-${index}`;
          return (
            <StyledTableRow
              hover
              selected={false}
              key={row.id}
              className="column-row"
            >
              <TableCell
                className="first_column"
                component="th"
                id={labelId}
                scope="row"
                sx={{ backgroundColor: index % 2 === 0 ? '#eeeeee' : '' }}
                padding="none"
              >
                <FlexBoxBetween
                  sx={{
                    color: '#373737',
                    paddingTop: 2.3,
                    paddingBottom: 2.3,
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}
                >
                  <FlexBox>
                    <Box marginLeft={1}>
                      <Link
                        href={row.url}
                        target="_blank"
                        color="#373737"
                        underline="hover"
                        className="table-link_text-prospect"
                      >
                        {row.url}
                      </Link>
                    </Box>
                  </FlexBox>
                  <Tooltip title="View Claim">
                    <IconButton
                      aria-label="more"
                      id="long-button"
                      aria-haspopup="true"
                      onClick={() => handleEdit(row.id)}
                    >
                      <ArticleIcon
                        sx={{
                          color: 'purple',
                          '&:hover': { color: 'purple' },
                        }}
                      />
                    </IconButton>
                  </Tooltip>
                </FlexBoxBetween>
              </TableCell>
              <TableCellCustom className="table_overflow-text">
                {row.site.name}
              </TableCellCustom>{' '}
              <TableCellCustom className="table_overflow-text">
                {row.project_name ?? ''}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.owner.username}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.approved_by?.username ?? ''}
              </TableCellCustom>
              <TableCellCustom className="table_overflow-text">
                {row.total_points}
              </TableCellCustom>
              <TableCellCustom>
                {dayjs(row.created).format('MM-DD-YYYY')}
              </TableCellCustom>{' '}
              <TableCellCustom>
                {row.found_date
                  ? dayjs(row.found_date).format('MM-DD-YYYY')
                  : ''}
              </TableCellCustom>
              <TableCellCustom>
                {row.date_approved
                  ? dayjs(row.date_approved).format('MM-DD-YYYY')
                  : ''}
              </TableCellCustom>
            </StyledTableRow>
          );
        })}
      </TableWrapper>
      <MainDrawer openDrawer={openDrawer} setOpenDrawer={setOpenDrawer}>
        <EditClaim
          setOpenDrawer={setOpenDrawer}
          fetchInfo={fetchInfo}
          handleMessage={handleMessage}
          claimLink={claimEdit}
          disabledAll
        />
      </MainDrawer>
    </>
  );
};

export default MasterLinks;
