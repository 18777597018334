import React, { useContext, useEffect, useState } from 'react';
import { CircularProgress, InputAdornment, InputLabel } from '@mui/material';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import CancelIcon from '@mui/icons-material/Cancel';
import { UserContextType } from '../../../models/User';
import Context from '../../../context/UserContext';
import { TextInputField } from '../../../components/inputs';

import {
  OwnersSelectLinkFormField,
  SelectFormInput,
  StatusSelectLinkFormField,
} from '../../../components/Forms/FormInputs';
import { FormInputsProps } from '../../../constants/form';
import BoxAddSettings from '../../../components/Forms/BoxAddSettings';
import {
  OwnersFieldInterface,
  SitesFieldInterface,
  StatusFieldInterface,
} from './FormModel';
import useLinkForm from '../../../stores/links/useLinkForm';
import { GlobalContextType, LoadingContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import { BasicArray } from '../../../models/General';
import { ObjectLinks } from '../../../models/Forms';
import { isURL } from '../../../helpers/validateInputs';
import { rulesCannibalization } from '../../../helpers/links';
import { IS_USER_ADMIN } from '../../../constants/user';
import LoadingContext from '../../../context/LoadingContext';

interface LinkDetailsProps {
  ownersField: OwnersFieldInterface;
  statusField: StatusFieldInterface;
  sitesField: SitesFieldInterface;
  onFormValidityChange: (isValid: boolean) => void;
}

interface FormErrors {
  url?: string;
  site?: string;
  status?: string;
  owner?: string;
}

const LinkDetails: React.FC<LinkDetailsProps> = ({
  ownersField,
  statusField,
  sitesField,
  onFormValidityChange,
}) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const { linkForm, updateLinkForm, replaceLinkForm } = useLinkForm();
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;
  const { isLoadingContext } = useContext(LoadingContext) as LoadingContextType;
  const [cannibalizedId, setCannibalizedId] = useState<null | number>(null);
  const [validLink, setValidLink] = useState(false);

  const [formErrors, setFormErrors] = useState<FormErrors>({});

  const [loadingUrl, setLoadingUrl] = useState(false);

  const validateField = <K extends keyof ObjectLinks>(
    name: K,
    value: ObjectLinks[K],
  ) => {
    const errors = { ...formErrors };

    if (name === 'url') {
      if (!value) {
        errors.url = 'URL is required';
        setValidLink(false);
      } else if (!isURL(value as string)) {
        errors.url = 'Invalid URL format';
        setValidLink(false);
      } else {
        errors.url = undefined;
      }
    }

    if (name === 'site' && !value) errors.site = 'Site selection is required';
    else if (name === 'site') errors.site = undefined;

    if (name === 'status' && !value) errors.status = 'Status is required';
    else if (name === 'status') errors.status = undefined;

    if (name === 'owner' && !value)
      errors.owner = 'Owner selection is required';
    else if (name === 'owner') errors.owner = undefined;

    setFormErrors(errors);
  };

  const handleInputChange = <K extends keyof ObjectLinks>(
    name: K,
    value: ObjectLinks[K],
  ) => {
    updateLinkForm({ [name]: value });

    validateField(name, value);
  };

  const validateRulesCannibalization = async (value: string) => {
    if (!loadingUrl) setLoadingUrl(true);

    const errors = { ...formErrors };
    try {
      const resp = await rulesCannibalization(
        linkForm.site,
        encodeURIComponent(value),
        UserContext.id,
        undefined,
      );
      if (resp.cannibalized_link_id !== null)
        setCannibalizedId(resp.cannibalized_link_id);
      else if (resp.cannibalized_link_id === null) setCannibalizedId(null);
      if (resp.valid) {
        setValidLink(true);
        if (resp.pending_list.length > 0) {
          // changingEntry(resp.pending_list[0]);
          console.log('ahh entro');
        } else if (formErrors.url) {
          errors.url = undefined;
        }
        if (resp.message !== '') errors.url = resp.message;
        else formErrors.url = undefined;
      } else if (IS_USER_ADMIN.includes(UserContext.profile.role)) {
        errors.url = resp.message;
        setValidLink(true);
      } else {
        setValidLink(false);
        errors.url = resp.message;
      }
      setFormErrors(errors);
    } catch (err) {
      console.log(err);
    } finally {
      setLoadingUrl(false);
    }
  };

  const inputsLinkSection: FormInputsProps[] = [
    {
      id: 0,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <InputLabel
            htmlFor="url"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
            }}
          >
            URL <span className="required-fields_dot">*</span>
          </InputLabel>
          <TextInputField
            onBlur={e => {
              let copyURL = e.target.value;
              if (e.target.value === '') return;

              if (!/(http(s?)):\/\//i.test(copyURL)) {
                copyURL = `https://${copyURL}`;
                handleInputChange('url', copyURL);
              }

              if (isURL(copyURL)) validateRulesCannibalization(copyURL);
            }}
            value={linkForm.url}
            name="url"
            disabled={isLoadingContext.loading || loadingUrl}
            error={!!formErrors.url}
            onChange={e => handleInputChange('url', e.target.value)}
            size="small"
            placeholder="URL"
            id="url"
            helperText={formErrors.url}
            fullWidth
            InputProps={
              loadingUrl
                ? {
                    endAdornment: (
                      <InputAdornment position="start">
                        <CircularProgress sx={{ color: '#ABABAB' }} size={16} />
                      </InputAdornment>
                    ),
                  }
                : {
                    endAdornment: (
                      <InputAdornment position="end">
                        <>
                          {validLink === true && (
                            <CheckCircleIcon fontSize="inherit" />
                          )}
                          {validLink === false && (
                            <CancelIcon fontSize="inherit" />
                          )}
                        </>
                      </InputAdornment>
                    ),
                  }
            }
          />
        </>
      ),
    },
    {
      id: 1,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            value={
              sitesField.sitesOptions.find(
                item => item.id === linkForm.site,
              ) ?? { id: 0, name: '' }
            }
            onBlur={(value: string) => {
              if (
                sitesField.sitesOptions.find(
                  item => item.name.toLowerCase() === value.toLowerCase(),
                )
              )
                validateField('site', 1);
              if (
                value === '' ||
                !sitesField.sitesOptions.find(
                  item => item.name.toLowerCase() === value.toLowerCase(),
                )
              )
                validateField('site', 0);
            }}
            options={sitesField.sitesOptions}
            disabled={sitesField.loadingSites || isLoadingContext.loading}
            disabledId={undefined}
            placeholder="Sites"
            error={!!formErrors.site}
            errorText={formErrors.site}
            loading={sitesField.loadingSites}
            required
            onChange={(value: BasicArray | null) => {
              const idSite = value ? (value.id as number) : 0;

              handleInputChange('site', idSite);
            }}
          />
        </>
      ),
    },
    {
      id: 2,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <StatusSelectLinkFormField
            value={linkForm.status}
            loading={statusField.loadingStatusOptions}
            error={!!formErrors.status}
            onChangeFunction={(value: string) =>
              handleInputChange('status', value)
            }
            required
            nameInput="status"
            options={statusField.statusOptions}
            label="Status"
            disabled={
              statusField.loadingStatusOptions || isLoadingContext.loading
            }
          />
        </>
      ),
    },
    {
      id: 3,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <OwnersSelectLinkFormField
          required
          loading={ownersField.loadingOwners}
          error={!!formErrors.owner}
          errorText={formErrors.owner ?? ''}
          value={linkForm.owner}
          onChangeFunction={(value: number) =>
            handleInputChange('owner', value)
          }
          options={ownersField.owners}
          nameInput="owner"
          label="Owner"
          disabled={
            ownersField.owners.length === 0 ||
            isLoadingContext.loading ||
            ownersField.loadingOwners
          }
        />
      ),
    },
  ];

  useEffect(() => {
    if (linkForm.site !== GlobalContext.site_selected)
      updateLinkForm({ site: GlobalContext.site_selected });
  }, [GlobalContext.site_selected]);

  useEffect(() => {
    const object = { ...linkForm };
    if (linkForm.owner === 0) object.owner = UserContext.id;
    if (linkForm.assignee === 0) object.assignee = UserContext.id;
    if (linkForm.site === 0) object.site = GlobalContext.site_selected ?? 0;
    replaceLinkForm(object);
  }, []);

  const isFormValid = (): boolean => {
    // Check if any of the formErrors are present
    const hasErrors = !!(
      formErrors.site ||
      formErrors.status ||
      formErrors.owner
    );

    // Check if all required fields have values
    const hasValidValues = !!(
      linkForm.url &&
      linkForm.site !== 0 &&
      linkForm.status &&
      linkForm.owner
    );

    // The form is valid if there are no errors and all fields have values
    return !hasErrors && hasValidValues && validLink && !loadingUrl;
  };
  useEffect(() => {
    onFormValidityChange(isFormValid());
  }, [formErrors, linkForm, onFormValidityChange, loadingUrl]);
  return (
    <>
      <BoxAddSettings title="Link" pl={0} pr={0} mb={1} pt={1.5}>
        <div className="form-grids">
          {inputsLinkSection.map(input => (
            <div key={input.id} className={input.fullSpace ? 'whole-grid' : ''}>
              {input.body}
            </div>
          ))}
        </div>
      </BoxAddSettings>
    </>
  );
};

export default LinkDetails;
