import React, { useContext, useEffect, useState } from 'react';
import AddIcon from '@mui/icons-material/Add';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import dayjs from 'dayjs';
import HelpRoundedIcon from '@mui/icons-material/HelpRounded';
import ModeEditOutlineOutlinedIcon from '@mui/icons-material/ModeEditOutlineOutlined';
import relativetime from 'dayjs/plugin/relativeTime';
import {
  Box,
  IconButton,
  Link,
  TableCell,
  Tooltip,
  Typography,
} from '@mui/material';
import TableWrapper from '../../components/Table/TableWrapper';
import { prospectsAssignedUserHead } from '../../models/Table';
import {
  FlexBox,
  FlexBoxBetween,
  TableCellCustom,
} from '../../components/tableItems';
import { UserLinksResult } from '../../models/Prospecting';
import ProspectingHeadEligible from './ProspectingHeadEligible';
import AddLink from '../../components/Forms/Links/AddLink';
import { formTemplateLink } from '../../models/Forms';
import MainDrawer from '../../components/Drawers/MainDrawer';
import GeneralContext from '../../context/GeneralActions';
import { GlobalContextType } from '../../models/Context';
import { StyledTableRow } from '../../components/LinksTable/LinksTable';
import ChangeStatus from './Modals/ChangeStatus';
import { DefaultCheckbox } from '../../components/inputs';
import { Order } from '../../models/General';
import { UserContextType } from '../../models/User';
import Context from '../../context/UserContext';

interface ProspectingTableProps {
  loading: boolean;
  error: boolean;
  prospects: UserLinksResult[];
  fetchInfo: () => Promise<void>;
  handleMessage: (message: string, severity: 'success' | 'error') => void;
  count: number;
  notesLinks: number[];
  searchProps?: {
    onKeyDown: () => void;
    value?: string;
    onChangeNormal: (query: string) => void;
    disabled: boolean;
  };
}

const avoidAdd = ['trainer'];

const ProspectingEligibleTable = ({
  loading,
  error,
  prospects,
  fetchInfo,
  handleMessage,
  count,
  notesLinks,
  searchProps,
}: ProspectingTableProps) => {
  dayjs.extend(relativetime);
  const { UserContext } = useContext(Context) as UserContextType;
  const [statusIds, setStatusIds] = useState<number[]>([]);
  const [selected, setSelected] = useState<number[]>([]);
  const [modal, setModal] = useState(false);
  const [open, setOpen] = useState(false);
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [prospectFields, setProspectFields] = useState({
    user: 0,
    site: 0,
    affinity: 0,
    link: '',
    audience: 0,
    id: 0,
    template: 0,
    tab: 0,
  });
  const [order, setOrder] = React.useState<Order>('desc');
  const [orderBy, setOrderBy] = React.useState<string>('created');

  const handleRequestSort = (property: string) => {
    const isSame = orderBy === property;
    let orderRequest = order;
    if (isSame) {
      orderRequest = order === 'asc' ? 'desc' : 'asc';
      setOrder(order === 'asc' ? 'desc' : 'asc');
    }
    setOrderBy(property);
    setGlobalContext({
      ...GlobalContext,
      assigned_me: {
        ...GlobalContext.assigned_me,
        order: `${orderRequest === 'desc' ? '-' : ''}${property}`,
      },
    });
  };

  const handleChangePage = (event: unknown, newPage: number) => {
    setGlobalContext({
      ...GlobalContext,
      assigned_me: { ...GlobalContext.assigned_me, page: newPage },
    });
    window.scrollTo(0, 0);
  };

  const handleChangeRowsPerPage = (value: number) => {
    setGlobalContext({
      ...GlobalContext,
      assigned_me: {
        ...GlobalContext.assigned_me,
        page: 1,
        page_size: value,
      },
    });
    window.scrollTo(0, 0);
  };
  const handleAddLink = (
    user: number,
    site: number,
    aud: number,
    aff: number,
    link: string,
    id: number,
    template: number,
    tab: number,
  ) => {
    setProspectFields({
      id,
      user,
      site,
      affinity: aff,
      audience: aud,
      link,
      template,
      tab,
    });
    setOpen(true);
  };

  const handleAllClick = () => {
    if (selected.length === prospects.length) {
      setSelected([]);
      return;
    }
    const ids = prospects.map(item => item.id);

    setSelected(ids);
  };

  const openEditStatusSingular = (id: number) => {
    setStatusIds([id]);
    setModal(true);
  };

  const openEditStatusBulk = () => {
    setStatusIds(selected);
    setModal(true);
  };

  const handleSelect = (id: number) => {
    const copyArr = [...selected];
    const positionId = copyArr.indexOf(id);
    if (positionId === -1) {
      copyArr.push(id);
    } else {
      copyArr.splice(positionId, 1);
    }
    setSelected(copyArr);
  };

  useEffect(() => {
    setSelected([]);
    setStatusIds([]);
  }, [prospects]);

  return (
    <>
      <TableWrapper
        loading={loading}
        error={error}
        type="prospecting"
        search
        searchProps={searchProps}
        pagination
        totalCells={10}
        fetchInfo={fetchInfo}
        itemsLength={prospects.length}
        countItems={count}
        pageSize={GlobalContext.assigned_me.page_size}
        page={GlobalContext.assigned_me.page}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
        HeadOfTable={
          <ProspectingHeadEligible
            order={order}
            orderBy={orderBy}
            onRequestSort={handleRequestSort}
            handleClick={handleAllClick}
            selected={selected.length}
            openEditStatusBulk={openEditStatusBulk}
            checked={
              prospects.length > 0 && selected.length === prospects.length
            }
            indeterminate={
              selected.length > 0 && selected.length < prospects.length
            }
            headCells={prospectsAssignedUserHead}
          />
        }
      >
        {prospects.map((row, index) => {
          const labelId = `prospect-table-checkbox-${index}`;
          return (
            <StyledTableRow
              hover
              selected={false}
              role="checkbox"
              key={row.id}
              className="column-row"
            >
              <TableCell
                className="first_column"
                sx={{ backgroundColor: index % 2 === 0 ? '#eeeeee' : '' }}
                component="th"
                id={labelId}
                scope="row"
                padding="none"
              >
                <FlexBoxBetween
                  sx={{
                    color: '#373737',
                    paddingTop: 2.3,
                    paddingBottom: 2.3,
                    paddingRight: 1,
                    paddingLeft: 1,
                  }}
                >
                  <FlexBox>
                    <DefaultCheckbox
                      checked={selected.indexOf(row.id) !== -1}
                      onClick={() => handleSelect(row.id)}
                      size="small"
                    />
                    <Box marginLeft={1}>
                      <Typography
                        color="#373737"
                        fontWeight={600}
                        className="table-link_text-prospect"
                        marginBottom={1}
                      >
                        {row.prospecting_result.title}
                      </Typography>
                      <Link
                        href={row.prospecting_result.url}
                        target="_blank"
                        color="#373737"
                        underline="hover"
                        className="table-link_text-prospect"
                      >
                        {row.prospecting_result.url}
                      </Link>
                    </Box>
                  </FlexBox>
                  <FlexBox>
                    {row.pending_link_id !== null &&
                      !avoidAdd.includes(UserContext.profile.role) && (
                        <Tooltip title="Notes">
                          <IconButton
                            aria-label="more"
                            id="long-button"
                            aria-haspopup="true"
                            onClick={() =>
                              handleAddLink(
                                row.user.id,
                                row.site ? row.site.id : 0,
                                row.audience ? row.audience.id : 0,
                                row.affinity ? row.affinity.id : 0,
                                row.prospecting_result.url,
                                row.id,
                                row.template ? row.template.id : 0,
                                1,
                              )
                            }
                          >
                            <FlexBox mr={0.6}>
                              <ChatOutlinedIcon
                                sx={{
                                  color: '#84D0F0',
                                  '&:hover': { color: '#84D0F0' },
                                }}
                              />
                              <Typography
                                fontSize={13}
                                marginLeft={0.7}
                                color="rgba(0, 0, 0, 0.5)"
                              >
                                {notesLinks[index]}
                              </Typography>
                            </FlexBox>
                          </IconButton>
                        </Tooltip>
                      )}
                    <Tooltip title="Edit Status">
                      <IconButton
                        aria-label="more"
                        id="long-button"
                        aria-haspopup="true"
                        onClick={() => openEditStatusSingular(row.id)}
                      >
                        <ModeEditOutlineOutlinedIcon
                          sx={{
                            color: '#7F7F7F',
                            '&:hover': { color: '#7F7F7F' },
                          }}
                        />
                      </IconButton>
                    </Tooltip>
                    {!avoidAdd.includes(UserContext.profile.role) && (
                      <Tooltip title="Create Link">
                        <IconButton
                          aria-label="more"
                          id="long-button"
                          aria-haspopup="true"
                          onClick={() =>
                            handleAddLink(
                              row.user.id,
                              row.site ? row.site.id : 0,
                              row.audience ? row.audience.id : 0,
                              row.affinity ? row.affinity.id : 0,
                              row.prospecting_result.url,
                              row.id,
                              row.template ? row.template.id : 0,
                              0,
                            )
                          }
                        >
                          <AddIcon
                            sx={{
                              color: '#6EBD0C',
                              '&:hover': { color: '#6EBD0C' },
                            }}
                          />
                        </IconButton>
                      </Tooltip>
                    )}
                  </FlexBox>
                </FlexBoxBetween>
              </TableCell>
              <TableCellCustom align="center" className="table_overflow-text">
                {row.user.username}
              </TableCellCustom>
              <TableCellCustom align="center" className="table_overflow-text">
                {row.previous_project_outreach === true ? 'Yes' : 'No'}
              </TableCellCustom>
              <TableCellCustom align="center" className="table_overflow-text">
                <FlexBox>
                  <Tooltip title={row.query}>
                    <IconButton sx={{ fontSize: 17 }}>
                      <HelpRoundedIcon fontSize="inherit" />
                    </IconButton>
                  </Tooltip>
                  {row.query}
                </FlexBox>
              </TableCellCustom>
              <TableCell align="center">
                <div className="status_row">{row.status}</div>
              </TableCell>
              <TableCellCustom align="center" className="table_overflow-text">
                {row.site ? row.site.name : ''}
              </TableCellCustom>
              <TableCellCustom align="center" className="table_overflow-text">
                {row.template ? row.template.title : ''}
              </TableCellCustom>
              <TableCellCustom align="center" className="table_overflow-text">
                {row.audience ? row.audience.name : ''}
              </TableCellCustom>
              <TableCellCustom align="center" className="table_overflow-text">
                {row.affinity ? row.affinity.name : ''}
              </TableCellCustom>
              <TableCellCustom align="center">
                {dayjs(row.created).format('MM-DD-YYYY')}
              </TableCellCustom>
              {/* <TableCellCustom align="center">
                {dayjs(row.modified).format('MM-DD-YYYY')}
              </TableCellCustom> */}
            </StyledTableRow>
          );
        })}
      </TableWrapper>
      <MainDrawer openDrawer={open} setOpenDrawer={setOpen}>
        <AddLink
          setOpenDrawer={setOpen}
          tab={prospectFields.tab}
          type="add"
          fetchLinks={fetchInfo}
          showMessage={handleMessage}
          tempsLinks={formTemplateLink}
          modified=""
          siteProspect={prospectFields.site}
          userProspect={prospectFields.user}
          affinityProspect={prospectFields.affinity}
          audienceProspect={prospectFields.audience}
          linkProspect={prospectFields.link}
          prospectId={prospectFields.id}
          templateProspect={prospectFields.template}
        />
      </MainDrawer>
      <ChangeStatus
        refreshInfo={fetchInfo}
        showMessage={handleMessage}
        open={modal}
        closeModal={setModal}
        ids={statusIds}
      />
    </>
  );
};

export default ProspectingEligibleTable;
