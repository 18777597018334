import React, { useState, useContext, useEffect } from 'react';
import {
  AppBar as MuiAppBar,
  AppBarProps as MuiAppBarProps,
  Toolbar,
  Box,
  FormControl,
  Select,
  InputBase,
  Tooltip,
  Menu,
  IconButton,
  MenuItem,
  Typography,
  styled,
  Avatar,
} from '@mui/material';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useNavigate } from 'react-router-dom';
import logo from '../../../assets/logos/logoMain.png';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import routesNames from '../../../routes/customRoutes';
import { GlobalContextType, RequestContextType } from '../../../models/Context';
import RequestContext from '../../../context/RequestContext';
import MyProfile from '../../Profile/MyProfile';
import useSites from '../../../customHooks/useSites';
import GeneralContext from '../../../context/GeneralActions';
import { ENVIRONMENTS, ROLES_USER } from '../../../constants/user';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const drawerWidth = 190;
interface Props {
  open: boolean;
}

const adminRoles = ['administrator', 'dev admin'];

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: prop => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  width: `calc(100% - 45px)`,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open }: Props) => {
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { UserContext, setUserContext } = useContext(
    Context,
  ) as UserContextType;
  const { GlobalContext, setGlobalContext } = useContext(
    GeneralContext,
  ) as GlobalContextType;
  const [openModalProfile, setOpenModalProfile] = useState(false);
  const { RequestsContext, setRequestsContext } = useContext(
    RequestContext,
  ) as RequestContextType;
  const navigate = useNavigate();
  const { sites, loading } = useSites({
    userId: UserContext.id,
    minimal: true,
  });

  const BootstrapInput = styled(InputBase)(() => ({
    '& .MuiInputBase-input': {
      position: 'relative',
      border: 'none',
      fontSize: 12.2,
      padding: '5px 15px 5px 10px',
      '&:focus': {
        border: 'none',
      },

      '&:hover': {
        color: '#616161',
      },
    },
  }));

  const logOut = () => {
    localStorage.removeItem('backlink.user');
    sessionStorage.removeItem('backlink.user');
    sessionStorage.removeItem('backlink.roletemp');
    navigate(routesNames.login);
  };

  useEffect(() => {
    if (sites.length > 0 && GlobalContext.site_selected === 0 && !loading) {
      const localSite = parseInt(
        JSON.parse(
          localStorage.getItem('backlink.siteselected') ?? JSON.stringify('0'),
        ),
        10,
      );

      if (localSite !== 0 && sites.find(item => item.id === localSite)) {
        setGlobalContext({
          ...GlobalContext,
          site_selected: localSite,
        });
      } else {
        setGlobalContext({
          ...GlobalContext,
          site_selected: sites[0].id,
        });
      }
    }
    setRequestsContext({
      ...RequestsContext,
      sites: sites.sort((a, b) => (a.name < b.name ? -1 : 1)),
    });
  }, [sites]);

  const changeRole = (value: string) => {
    localStorage.setItem('backlink.roletemp', JSON.stringify(value));

    window.location.reload();
  };

  return (
    <>
      <AppBar
        sx={{
          backgroundColor: '#ECECEC',
          boxShadow: 'none',
        }}
        position="fixed"
        open={open}
      >
        <Toolbar>
          <img src={logo} alt="Backlink Logo" />
          <Box sx={{ flexGrow: 1, display: { xs: 'flex' } }}>
            <FormControl sx={{ my: 2, ml: 2, minWidth: 120 }}>
              <Select
                defaultValue={sites.length > 0 ? sites[0].id : 0}
                IconComponent={KeyboardArrowDownIcon}
                value={GlobalContext.site_selected}
                sx={{
                  color: '#373737',
                  fontWeight: 700,
                  boxShadow: 'none',
                  '.MuiOutlinedInput-notchedOutline': {
                    border: 0,
                    outline: 'none',
                  },
                  '& .Mui-focused': {
                    '& .MuiOutlinedInput-notchedOutline': { outline: 'none' },
                  },
                }}
                onChange={e => {
                  localStorage.setItem(
                    'backlink.siteselected',
                    JSON.stringify(e.target.value),
                  );
                  setGlobalContext({
                    ...GlobalContext,
                    site_selected: e.target.value as number,
                  });
                }}
                input={<BootstrapInput />}
              >
                <MenuItem value={0} disabled>
                  {loading && <em>Loading Sites</em>}
                  {sites.length === 0 && !loading && <em>No sites assigned</em>}
                  {sites.length > 0 && !loading && <em>Select a Site</em>}
                </MenuItem>
                {sites.map(option => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
          <Box sx={{ flexGrow: 0, alignItems: 'center', display: 'flex' }}>
            {(adminRoles.includes(
              UserContext.real_role ?? UserContext.profile.role,
            ) ||
              (UserContext.profile.training_env_allowed &&
                ENVIRONMENTS.includes(process.env.REACT_APP_ENV ?? ''))) && (
              <FormControl sx={{ my: 2, ml: 2, minWidth: 120 }}>
                <Select
                  defaultValue={sites.length > 0 ? sites[0].id : 0}
                  IconComponent={KeyboardArrowDownIcon}
                  value={UserContext.profile.role}
                  sx={{
                    color: '#373737',
                    fontWeight: 700,
                    boxShadow: 'none',
                    '.MuiOutlinedInput-notchedOutline': {
                      border: 0,
                      outline: 'none',
                    },
                    '& .Mui-focused': {
                      '& .MuiOutlinedInput-notchedOutline': {
                        outline: 'none',
                      },
                    },
                  }}
                  onChange={e => changeRole(e.target.value as string)}
                  input={<BootstrapInput />}
                >
                  {ROLES_USER.map(option => (
                    <MenuItem key={option.value} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            )}
            <Tooltip title="Open options">
              <IconButton
                size="large"
                sx={{ borderRadius: 20 }}
                onClick={event => setAnchorElUser(event.currentTarget)}
              >
                {UserContext.avatar === '' && <div className="navbar-user" />}
                {UserContext.avatar !== '' && (
                  <Avatar
                    alt="User Avatar"
                    src={UserContext.avatar}
                    sx={{ width: '24px', height: '24px' }}
                  />
                )}
                <Typography
                  textAlign="right"
                  sx={{
                    ml: 1,
                    mr: 0.2,
                    fontWeight: 400,
                    color: '#373737',
                    fontSize: '14px',
                  }}
                >
                  {UserContext.first_name !== ''
                    ? `${UserContext.first_name} ${UserContext.last_name}`
                    : 'User'}
                </Typography>
                <KeyboardArrowDownIcon />
              </IconButton>
            </Tooltip>
            <Menu
              sx={{ mt: '45px' }}
              id="menu-appbar"
              anchorEl={anchorElUser}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              keepMounted
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
              open={Boolean(anchorElUser)}
              onClose={() => setAnchorElUser(null)}
            >
              <MenuItem
                onClick={() => {
                  setAnchorElUser(null);
                  setOpenModalProfile(true);
                }}
                sx={{ minWidth: 120 }}
              >
                <Typography textAlign="start">Edit My Profile</Typography>
              </MenuItem>
              <MenuItem onClick={() => logOut()} sx={{ minWidth: 120 }}>
                <Typography textAlign="start">Log Out</Typography>
              </MenuItem>
            </Menu>
          </Box>
        </Toolbar>
      </AppBar>
      <MyProfile
        open={openModalProfile}
        closeModal={setOpenModalProfile}
        sitesTable={sites}
      />
    </>
  );
};

export default Navbar;
