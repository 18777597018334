import { Box, styled, TableCell, Switch } from '@mui/material';

export const FlexBox = styled(Box)({
  alignItems: 'center',
  display: 'flex',
});

export const FlexBoxBetween = styled(Box)({
  alignItems: 'center',
  display: 'flex',
  justifyContent: 'space-between',
});

export const TableCellCustom = styled(TableCell)({
  color: '#787878',
  textTransform: 'capitalize',
  fontSize: 11.2,
});

export const SwitchCustom = styled(Switch)(({ theme }) => ({
  width: 40,
  height: 20,
  padding: 0,
  display: 'flex',
  '&:active': {
    '& .MuiSwitch-thumb': {
      width: 15,
    },
    '& .MuiSwitch-switchBase.Mui-checked': {},
  },
  '& .MuiSwitch-switchBase': {
    padding: 2,
    color: 'gray',
    '&.Mui-checked': {
      transform: 'translateX(20px)',
      color: '#000000',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: 'white',
      },
    },
  },
  '& .MuiSwitch-thumb': {
    boxShadow: '0 2px 4px 0 rgb(0 35 11 / 20%)',
    height: 16,
    transition: theme.transitions.create(['width'], {
      duration: 200,
    }),
  },
  '& .MuiSwitch-track': {
    borderRadius: 16,
    border: '1px solid rgba(0, 0, 0, 0.4)',
    opacity: 1,
    backgroundColor: 'white',
    boxSizing: 'border-box',
  },
}));
