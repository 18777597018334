/* eslint-disable react/no-children-prop */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/no-array-index-key */
import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Divider,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Typography,
} from '@mui/material';
import RightColumns from '../../../components/Columns/RightColumns';
import TabParent from '../../../components/Tab/TabParent';
import { FlexBox, FlexBoxBetween } from '../../../components/tableItems';
import { FormButtons, MainButton } from '../../../components/buttons';
import MainForm from './MainForm';
import TabPanel from '../../../components/Tab/TabPanel';
import useSidebar from '../../../stores/links/useSidebar';
import LinkDetails from './LinkDetails';
import useOwners from '../../../customHooks/useOwners';
import useLinkStatus from '../../../customHooks/useLinkStatus';
import useLinkForm from '../../../stores/links/useLinkForm';
import Context from '../../../context/UserContext';
import { UserContextType } from '../../../models/User';
import { GlobalContextType } from '../../../models/Context';
import GeneralContext from '../../../context/GeneralActions';
import useSites from '../../../customHooks/useSites';
import ContactsAdd from './ContactsAdd';
import FeedbackMessage from '../../../components/FeedbackMessage';
import { MessagesInt, SeverityType } from '../../../models/General';
import { createLink, sendErrorSubmit } from '../../../helpers/links';
import { LinkFormDefault } from '../../../models/LinkForm';

const FormBox = () => {
  // GLOBAL VARIABLES WITH CONTEXT
  const { UserContext } = useContext(Context) as UserContextType;
  const { GlobalContext } = useContext(GeneralContext) as GlobalContextType;

  // GLOBAL VARIABLES ZUSTAND
  const { formSidebar, loadingForm, updateLoadingForm, updateSidebar } =
    useSidebar();
  const { linkForm, updateLinkForm, replaceLinkForm } = useLinkForm();

  // CUSTOM HOOKS FOR THE ENDPOINTS
  const { owners, loading: loadingOwners } = useOwners({ withFields: true });
  const { statusOptions, loadingStatusOptions } = useLinkStatus();
  const { sites: sitesOptions, loading: loadingSites } = useSites({
    userId: UserContext.id,
    minimal: true,
  });

  const [activeStep, setActiveStep] = useState(0);
  const [tabValue, setTabValue] = useState(0);
  const [tabs, setTabs] = useState([
    { id: 0, label: 'Details', disabled: true },
    { id: 1, label: 'Contacts', disabled: true },
    { id: 2, label: 'Notes', disabled: true },
    { id: 3, label: 'History', disabled: true },
    { id: 4, label: 'Template', disabled: true },
  ]);

  const [stepOneFormValid, setStepOneFormValid] = useState(false);
  const [stepTwoFormValid, setStepTwoFormValid] = useState(true);

  const [openMessage, setOpenMessage] = useState(false);
  const [messagesProps, setMessagesProps] = useState<MessagesInt>({
    message: '',
    severity: 'error',
  });

  const isStepOptional = (step: number) => {
    return false;
  };

  const handleFeedbackMessage = (message: string, severity?: SeverityType) => {
    setOpenMessage(true);
    setMessagesProps({ message, severity: severity ?? 'error' });
  };

  const steps = [
    {
      label: 'Link Details',
      content: (
        <LinkDetails
          ownersField={{ owners, loadingOwners }}
          statusField={{ statusOptions, loadingStatusOptions }}
          sitesField={{ sitesOptions, loadingSites }}
          onFormValidityChange={setStepOneFormValid}
        />
      ),
    },
    { label: 'Contacts', content: <Box>xd</Box> },
  ];

  const saveLink = async () => {
    updateLoadingForm(true);
    try {
      await createLink(linkForm);

      handleFeedbackMessage(`Link created successfully`, 'success');
      // fetchLinks();
      replaceLinkForm(LinkFormDefault);
      updateSidebar({ type: 'edit', disabled: true });
    } catch (err) {
      const error = err as any;
      const url = `POST ${process.env.REACT_APP_API_BASE_URL}/api/v2/links/`;
      await sendErrorSubmit({
        request_url: url,
        request_payload: JSON.stringify(linkForm),
        response: JSON.stringify(error.data),
        token: UserContext.token,
      });
      console.log(error.data);
      handleFeedbackMessage(`Error creating link`, 'error');
    } finally {
      updateLoadingForm(false);
    }
  };

  const handleNext = () => {
    if (activeStep === steps.length - 1) {
      saveLink();
      return;
    }

    setActiveStep(activeStep + 1);
  };

  useEffect(() => {
    if (formSidebar.type === 'add' && activeStep !== 0) setActiveStep(0);
  }, [formSidebar]);

  return (
    <>
      <RightColumns>
        <Typography
          fontWeight={700}
          fontSize={15}
          marginBottom={1.5}
          textTransform="capitalize"
          textAlign="center"
        >
          {formSidebar.type === 'add' && 'Create Link'}
          {formSidebar.type === 'edit' && 'Link Details'}
        </Typography>

        {/* EDIT LINKS */}
        {formSidebar.type === 'edit' && (
          <FlexBoxBetween marginBottom={1.5}>
            <FormButtons
              onClick={() => {}}
              disabled
              variant="contained"
              sx={{
                backgroundColor: '#FFFFFF',
                fontSize: 11,
                color: 'black',
                borderColor: 'grey',
                border: 1,
                '&:hover': {
                  backgroundColor: '#FFFFFF',
                },
              }}
            >
              Edit
            </FormButtons>
            <FlexBox>
              <FormButtons
                loading={false}
                variant="contained"
                sx={{
                  marginX: 1,
                  background: '#BAF372',
                  fontSize: 11,
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#BAF372',
                  },
                }}
                disabled
                onClick={() => {}}
              >
                Save
              </FormButtons>
              <FormButtons
                variant="contained"
                disabled
                loading={false}
                sx={{
                  fontSize: 11,
                  backgroundColor: '#84D0F0',
                  color: 'black',
                  '&:hover': {
                    backgroundColor: '#84D0F0',
                  },
                }}
                onClick={() => {}}
              >
                Save + Email
              </FormButtons>
            </FlexBox>
          </FlexBoxBetween>
        )}

        {/* ADD LINKS */}

        {formSidebar.type === 'add' && (
          <Box sx={{ width: '100%' }}>
            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((step, index) => {
                const stepProps: { completed?: boolean } = {};
                const labelProps: { optional?: React.ReactNode } = {};

                if (isStepOptional(index)) {
                  labelProps.optional = (
                    <Typography variant="caption" fontSize={11}>
                      Optional
                    </Typography>
                  );
                }

                return (
                  <Step key={index} {...stepProps}>
                    <StepLabel
                      {...labelProps}
                      sx={{
                        '.MuiStepLabel-label': {
                          fontSize: 12,
                        },
                      }}
                    >
                      {step.label}
                    </StepLabel>
                  </Step>
                );
              })}
            </Stepper>
            <Box mt={1} mb={2}>
              <FlexBoxBetween>
                <FormButtons
                  loading={false}
                  variant="contained"
                  sx={{
                    marginX: 1,
                    fontSize: 11,

                    backgroundColor: '#FFFFFF',
                    color: 'black',
                    borderColor: 'grey',
                    border: 1,
                    '&:hover': {
                      backgroundColor: '#FFFFFF',
                    },
                  }}
                  disabled={activeStep === 0 || loadingForm}
                  onClick={() => setActiveStep(activeStep - 1)}
                >
                  Back
                </FormButtons>
                <FormButtons
                  loading={loadingForm}
                  variant="contained"
                  sx={{
                    marginX: 1,
                    background: '#BAF372',
                    fontSize: 11,
                    color: 'black',
                    '&:hover': {
                      backgroundColor: '#BAF372',
                    },
                  }}
                  disabled={
                    activeStep === 0
                      ? !stepOneFormValid
                      : stepTwoFormValid || loadingForm
                  }
                  onClick={handleNext}
                >
                  {activeStep === steps.length - 1 ? 'Save' : 'Next'}
                </FormButtons>
              </FlexBoxBetween>
            </Box>
          </Box>
        )}

        <Divider />
        {formSidebar.type === 'edit' && (
          <>
            <Box
              sx={{
                borderBottom: 1,
                borderColor: 'divider',
                marginTop: 1,
              }}
            >
              <TabParent
                valueTab={tabValue}
                smaller
                setTab={setTabValue}
                tabs={tabs}
                center
              />
            </Box>
            <TabPanel value={tabValue} index={0}>
              <MainForm
                ownersField={{ owners, loadingOwners }}
                sitesField={{ sitesOptions, loadingSites }}
                statusField={{ statusOptions, loadingStatusOptions }}
              />
            </TabPanel>
          </>
        )}
        {formSidebar.type === 'add' && (
          <>
            <Box
              sx={{
                visibility: activeStep === 0 ? 'visible' : 'hidden',
                height: activeStep === 0 ? 'auto' : 0,
              }}
            >
              <LinkDetails
                ownersField={{ owners, loadingOwners }}
                statusField={{ statusOptions, loadingStatusOptions }}
                sitesField={{ sitesOptions, loadingSites }}
                onFormValidityChange={setStepOneFormValid}
              />
            </Box>
            <Box
              sx={{
                visibility: activeStep === 1 ? 'visible' : 'hidden',
                height: activeStep === 1 ? 'auto' : 0,
              }}
            >
              <ContactsAdd
                handleFeedbackMessage={handleFeedbackMessage}
                onContactsValidityChange={setStepTwoFormValid}
                stepTwoFormValid={stepTwoFormValid}
              />
            </Box>
          </>
        )}
      </RightColumns>
      <FeedbackMessage
        open={openMessage}
        setOpen={setOpenMessage}
        vertical="top"
        horizontal="right"
        severity={messagesProps.severity}
        message={messagesProps.message}
      />
    </>
  );
};

export default FormBox;
