import { create } from 'zustand';
import { LinkFormSidebarInterface } from '../../models/LinkForm';

interface LinkFormSidebarStore {
  formSidebar: LinkFormSidebarInterface;
  updateSidebar: (obj: LinkFormSidebarInterface) => void;
  loadingForm: boolean;
  updateLoadingForm: (value: boolean) => void;
}

const useSidebar = create<LinkFormSidebarStore>(set => ({
  formSidebar: { type: 'edit', disabled: true },
  loadingForm: false,
  updateSidebar: obj => set({ formSidebar: obj }),
  updateLoadingForm: value => set({ loadingForm: value }),
}));

export default useSidebar;
