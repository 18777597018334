export interface LinkFormSidebarInterface {
  type: 'add' | 'edit';
  disabled: boolean;
}

export const LinkFormDefault = {
  url: '',
  status: 'pending',
  is_cannibalization: false,
  owner: 0,
  assignee: 0,
  site: 0,
  affinity: 0,
  audience: 0,
  template: 0,
  contacts: [
    {
      name: '',
      email: '',
      is_form: false,
      status: 'open',
      is_default: true,
      validation_status: 'active',
    },
  ],
};
