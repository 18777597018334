import React, { useState } from 'react';
import { Box } from '@mui/material';
import useLinkForm from '../../../stores/links/useLinkForm';
import ContactsComponent from '../../../components/Forms/Links/ContactsComponent';
import { SeverityType } from '../../../models/General';
import {
  ContactsValuesType,
  contactValueDefaultError,
} from '../../../helpers/contacts';

interface ContactsAddProps {
  handleFeedbackMessage: (message: string, severity?: SeverityType) => void;
  onContactsValidityChange: (isValid: boolean) => void;
  stepTwoFormValid: boolean;
}

const ContactsAdd: React.FC<ContactsAddProps> = ({
  handleFeedbackMessage,
  onContactsValidityChange,
  stepTwoFormValid,
}) => {
  const { linkForm, updateLinkForm, replaceContacts } = useLinkForm();

  const [doNotDeleteContact, setDoNotDeleteContact] = useState('');
  const [contactErrors, setContactErrors] = useState();
  const [disabledContactButton, setDisabledContactButton] = useState(true);

  const [contactsValues, setContactsValues] = useState<ContactsValuesType[]>(
    new Array(linkForm.contacts.length).fill(contactValueDefaultError),
  );
  const updateContactsValues = (copyContactsValues: ContactsValuesType[]) =>
    setContactsValues(copyContactsValues);

  const addTextNote = (message: string) => {};

  const updateLinkToPending = () => updateLinkForm({ status: 'pending' });

  const handleDisableButtonContact = (value: boolean) =>
    onContactsValidityChange(value);

  return (
    <Box mt={2}>
      <ContactsComponent
        addTextNote={addTextNote}
        sendMessage={handleFeedbackMessage}
        updateLinkToPending={updateLinkToPending}
        allowOpenNewModalContact={false}
        openNewContactModal={() => {}}
        type="add"
        doNotDeleteContact={doNotDeleteContact}
        loadingCannibalizationChange={false}
        pendingId={linkForm.id && linkForm.id !== 0 ? linkForm.id : undefined}
        linkStatus={linkForm.status}
        updateContacts={replaceContacts}
        templateInfo={{
          template_id: linkForm.template,
          link_id: linkForm.id ?? 0,
          link_url: linkForm.url,
        }}
        originalContacts={undefined}
        handleDisableButtonContact={handleDisableButtonContact}
        disabledContactButton={stepTwoFormValid}
        contacts={linkForm.contacts}
        updateContactsValues={updateContactsValues}
        contactsValuesOriginal={contactsValues}
        site={linkForm.site}
        disableEditButton={false}
      />
    </Box>
  );
};

export default ContactsAdd;
