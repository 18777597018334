import React, { useContext, useState } from 'react';
import { InputLabel } from '@mui/material';
import BoxAddSettings from '../../../components/Forms/BoxAddSettings';
import { FormInputsProps } from '../../../constants/form';
import { TextInputField } from '../../../components/inputs';
import {
  OwnersSelectLinkFormField,
  SelectFormInput,
  StatusSelectLinkFormField,
} from '../../../components/Forms/FormInputs';
import useOwners from '../../../customHooks/useOwners';
import { sendBackObject } from '../../../helpers/helpersfunctions';
import Context from '../../../context/UserContext';
import { Owners, UserContextType } from '../../../models/User';
import { ROLES_LINKS } from '../../../constants/user';
import ContactsComponent from '../../../components/Forms/Links/ContactsComponent';
import { contactValueDefault } from '../../../helpers/contacts';
import {
  OwnersFieldInterface,
  SitesFieldInterface,
  StatusFieldInterface,
} from './FormModel';

interface MainFormProps {
  ownersField: OwnersFieldInterface;
  statusField: StatusFieldInterface;
  sitesField: SitesFieldInterface;
}

const MainForm: React.FC<MainFormProps> = ({
  ownersField,
  statusField,
  sitesField,
}) => {
  const { UserContext } = useContext(Context) as UserContextType;
  const [formErrors, setFormErrors] = useState<number[]>([]);
  const [errorLinkText, setErrorLinkText] = useState('');

  const checkErrorsInput = (inputId: number) => formErrors.includes(inputId);

  const inputsTargetingSection: FormInputsProps[] = [
    {
      id: 0,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            loading={false}
            error={false}
            options={[]}
            updateInput={() => {}}
            required
            disabled
            placeholder="Audience"
            value={{
              id: 0,
              name: '',
            }}
            onChange={() => {}}
          />
        </>
      ),
    },
    {
      id: 1,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            loading={false}
            error={false}
            options={[]}
            updateInput={() => {}}
            required
            disabled
            placeholder="Affinity"
            value={{
              id: 0,
              name: '',
            }}
            onChange={() => {}}
          />
        </>
      ),
    },
    {
      id: 2,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            loading={false}
            error={false}
            options={[]}
            updateInput={() => {}}
            required
            disabled
            placeholder="Template"
            value={{
              id: 0,
              name: '',
            }}
            onChange={() => {}}
          />
        </>
      ),
    },
  ];

  const inputsLinkSection: FormInputsProps[] = [
    {
      id: 0,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <InputLabel
            htmlFor="url"
            sx={{
              fontSize: 12,
              fontWeight: '700',
              color: 'rgba(0, 0, 0, 0.8)',
              marginBottom: 1,
            }}
          >
            URL <span className="required-fields_dot">*</span>
          </InputLabel>
          <TextInputField
            value=""
            name="url"
            disabled
            error={checkErrorsInput(0)}
            size="small"
            placeholder="URL"
            id="url"
            helperText={errorLinkText}
            fullWidth
          />
        </>
      ),
    },
    {
      id: 1,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <SelectFormInput
            clearIcon
            value={{
              id: 0,
              name: '',
            }}
            options={[]}
            disabled
            disabledId={undefined}
            placeholder="Sites"
            loading={false}
            required
            onChange={() => {}}
          />
        </>
      ),
    },
    {
      id: 2,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <>
          <StatusSelectLinkFormField
            value=""
            loading={false}
            error={false}
            onChangeFunction={() => {}}
            required
            nameInput="status"
            options={[]}
            label="Status"
            disabled
          />
        </>
      ),
    },
    {
      id: 3,
      value: '',
      fullSpace: true,
      required: true,
      body: (
        <OwnersSelectLinkFormField
          required
          loading={ownersField.loadingOwners}
          error={false}
          errorText=""
          value={UserContext.id}
          onChangeFunction={() => {}}
          options={ownersField.owners}
          nameInput="owner"
          label="Owner"
          disabled
        />
      ),
    },
  ];

  return (
    <>
      <BoxAddSettings title="Link" pl={0} pr={0} mb={1} pt={1.5}>
        <div className="form-grids">
          {inputsLinkSection.map(input => (
            <div key={input.id} className={input.fullSpace ? 'whole-grid' : ''}>
              {input.body}
            </div>
          ))}
        </div>
      </BoxAddSettings>

      {/* <BoxAddSettings title="Targeting" mb={1} pl={0} pr={0} pt={1.5}>
        <div className="form-grids">
          {inputsTargetingSection.map(input => (
            <div key={input.id} className={input.fullSpace ? 'whole-grid' : ''}>
              {input.body}
            </div>
          ))}
        </div>
      </BoxAddSettings> */}

      {/* <BoxAddSettings title="Contact" pl={0} pr={0}>
        <ContactsComponent
          addTextNote={() => {}}
          sendMessage={() => {}}
          updateLinkToPending={() => {}}
          allowOpenNewModalContact={false}
          openNewContactModal={() => {}}
          type="add"
          doNotDeleteContact=""
          loadingCannibalizationChange={false}
          pendingId={undefined}
          linkStatus=""
          updateContacts={() => {}}
          templateInfo={{
            link_id: 0,
            template_id: 0,
            link_url: '',
          }}
          originalContacts={undefined}
          handleDisableButtonContact={() => {}}
          disabledContactButton
          contacts={[
            {
              name: '',
              email: '',
              is_form: false,
              status: 'open',
              is_default: true,
              validation_status: 'active',
            },
          ]}
          updateContactsValues={() => {}}
          contactsValuesOriginal={[contactValueDefault]}
          site={0}
          disableEditButton
        />
      </BoxAddSettings> */}
    </>
  );
};

export default MainForm;
