export interface HeadCell {
  disablePadding: boolean;
  id: string;
  label: string;
  main?: boolean;
  disableSort: boolean;
}

export const linkHeadCells: HeadCell[] = [
  {
    id: 'url',
    disablePadding: true,
    disableSort: false,
    main: true,
    label: 'URL',
  },
  {
    id: 'status',
    disablePadding: false,
    disableSort: false,
    label: 'Status',
  },
  {
    id: 'owner__username',
    disableSort: false,
    disablePadding: false,
    label: 'Owner',
  },
  {
    disableSort: false,
    id: 'modified',
    disablePadding: false,
    label: 'Updated',
  },
  {
    id: 'site__name',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    disableSort: false,
    id: 'template__title',
    disablePadding: false,
    label: 'Template',
  },
  // {
  //   disableSort: false,
  //   id: 'affinity__name',
  //   disablePadding: false,
  //   label: 'Affinity',
  // },
  // {
  //   disableSort: false,
  //   id: 'audience__name',
  //   disablePadding: false,
  //   label: 'Audience',
  // },
  // {
  //   disableSort: false,
  //   id: 'created',
  //   disablePadding: false,
  //   label: 'Added',
  // },

  {
    disableSort: true,
    id: 'is_unsubscribed',
    disablePadding: true,
    label: 'Unsubscribed',
  },
  {
    disableSort: false,
    id: 'found_not_null',
    disablePadding: true,
    label: 'Found',
  },
];

export const linkUnclaimedHeadCells: HeadCell[] = [
  {
    id: 'url',
    disablePadding: true,
    disableSort: false,
    main: true,
    label: 'URL',
  },
  {
    id: 'site__name',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    disableSort: false,
    id: 'template__title',
    disablePadding: false,
    label: 'Template',
  },
  {
    disableSort: false,
    id: 'affinity__name',
    disablePadding: false,
    label: 'Affinity',
  },
  {
    disableSort: false,
    id: 'audience__name',
    disablePadding: false,
    label: 'Audience',
  },
  {
    disableSort: false,
    id: 'created',
    disablePadding: false,
    label: 'Added',
  },
  {
    disableSort: false,
    id: 'modified',
    disablePadding: false,
    label: 'Updated',
  },
  {
    disableSort: false,
    id: 'found_not_null',
    disablePadding: false,
    label: 'Found',
  },
  {
    id: 'owner__username',
    disableSort: false,
    disablePadding: false,
    label: 'Owner',
  },
];

export const userHeadCells: HeadCell[] = [
  {
    id: 'user',
    disablePadding: true,
    disableSort: false,
    label: 'User',
    main: true,
  },
  { id: 'role', disablePadding: false, disableSort: false, label: 'Role' },
  { id: 'status', disablePadding: false, disableSort: false, label: 'Status' },
  { id: 'sites', disablePadding: false, disableSort: false, label: 'Sites' },
  { id: 'options', disablePadding: false, disableSort: true, label: '' },
];

export const templatesHeadCells: HeadCell[] = [
  {
    id: 'title',
    disablePadding: true,
    disableSort: false,
    main: true,
    label: 'Template',
  },
  {
    id: 'site__name',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    id: 'owner__username',
    disableSort: false,
    disablePadding: false,
    label: 'Author',
  },
  {
    id: 'status',
    disablePadding: false,
    disableSort: false,
    label: 'Status',
  },
  {
    disableSort: false,
    id: 'created',
    disablePadding: false,
    label: 'Date Created',
  },
  {
    disableSort: false,
    id: 'modified',
    disablePadding: false,
    label: 'Date Modified',
  },
  {
    id: 'notes_count',
    disablePadding: false,
    disableSort: false,
    label: 'Notes',
  },
];

export const prospectingHeadsCell: HeadCell[] = [
  {
    id: 'url',
    disablePadding: true,
    disableSort: false,
    main: true,
    label: 'Show All',
  },
  {
    id: 'assigned__to',
    disablePadding: false,
    disableSort: false,
    label: 'Assigned To',
  },
  {
    id: 'sites',
    disablePadding: false,
    disableSort: false,
    label: 'Sites',
  },

  {
    id: 'audience',
    disablePadding: false,
    disableSort: false,
    label: 'Audience',
  },
  {
    id: 'affinity',
    disablePadding: false,
    disableSort: false,
    label: 'Affinity',
  },
  {
    id: 'template',
    disablePadding: false,
    disableSort: false,
    label: 'Templates',
  },
];

export const prospectsAssignedUserHead: HeadCell[] = [
  {
    id: 'url',
    disablePadding: true,
    disableSort: true,
    main: true,
    label: 'URL',
  },
  {
    id: 'assigned__to',
    disablePadding: false,
    disableSort: true,
    label: 'Assigned To',
  },
  {
    id: 'previous_project_outreach',
    disablePadding: false,
    disableSort: false,
    label: 'Prev Project Outreach',
  },
  {
    id: 'query',
    disablePadding: false,
    disableSort: false,
    label: 'Query',
  },
  {
    id: 'status',
    disablePadding: false,
    disableSort: true,
    label: 'Status',
  },
  {
    id: 'site',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    id: 'template',
    disablePadding: false,
    disableSort: false,
    label: 'Template',
  },
  {
    id: 'audience',
    disablePadding: false,
    disableSort: false,
    label: 'Audience',
  },
  {
    id: 'affinity',
    disablePadding: false,
    disableSort: false,
    label: 'Affinity',
  },
  {
    id: 'created',
    disablePadding: false,
    disableSort: false,
    label: 'Created',
  },
  // {
  //   id: 'modified',
  //   disablePadding: false,
  //   disableSort: false,
  //   label: 'Modified',
  // },
];

export const claimsHeadTable: HeadCell[] = [
  {
    id: 'link',
    disablePadding: true,
    disableSort: false,
    main: true,
    label: 'URL',
  },
  {
    id: 'link_type',
    disablePadding: false,
    disableSort: false,
    label: 'Link Type',
  },
  {
    id: 'site',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    id: 'manager',
    disablePadding: false,
    disableSort: false,
    label: 'Manager',
  },
  {
    id: 'owner',
    disablePadding: false,
    disableSort: false,
    label: 'Owner',
  },
  {
    id: 'duplicated',
    disablePadding: false,
    disableSort: false,
    label: 'Duplicated',
  },
  {
    id: 'domain_authority',
    disablePadding: false,
    disableSort: false,
    label: 'DA',
  },
  {
    id: 'link_scan_status',
    disablePadding: false,
    disableSort: false,
    label: 'Status',
  },
  {
    id: 'rejected_reason',
    disablePadding: false,
    disableSort: true,
    label: 'Rejected Reason',
  },
  {
    id: 'rejected_reason_detail',
    disablePadding: false,
    disableSort: true,
    label: 'Rejected Reason Detail',
  },
  {
    id: 'total_points',
    disablePadding: false,
    disableSort: false,
    label: 'Total Points',
  },
  {
    id: 'created',
    disablePadding: false,
    disableSort: false,
    label: 'Created',
  },
  {
    id: 'modified',
    disablePadding: false,
    disableSort: false,
    label: 'Modified',
  },
  {
    id: 'link__found',
    disablePadding: false,
    disableSort: false,
    label: 'Date Found',
  },
  {
    id: 'date_approved',
    disablePadding: false,
    disableSort: false,
    label: 'Approved',
  },
  {
    id: 'notes',
    disablePadding: false,
    disableSort: true,
    label: 'Notes',
  },
];

export const masterLinkHeadTable: HeadCell[] = [
  {
    id: 'link',
    disablePadding: true,
    disableSort: false,
    main: true,
    label: 'URL',
  },
  {
    id: 'site',
    disablePadding: false,
    disableSort: false,
    label: 'Site',
  },
  {
    id: 'link__project',
    disablePadding: false,
    disableSort: false,
    label: 'Project',
  },
  {
    id: 'owner',
    disablePadding: false,
    disableSort: false,
    label: 'Owner',
  },
  {
    id: 'approved_by',
    disablePadding: false,
    disableSort: false,
    label: 'Approver',
  },

  {
    id: 'total_points',
    disablePadding: false,
    disableSort: false,
    label: 'Total Points',
  },
  {
    id: 'created',
    disablePadding: false,
    disableSort: false,
    label: 'Created',
  },
  {
    id: 'found',
    disablePadding: false,
    disableSort: false,
    label: 'Found',
  },
  {
    id: 'date_approved',
    disablePadding: false,
    disableSort: false,
    label: 'Approved',
  },
];
